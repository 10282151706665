<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">VTokens</h3>
          <div class="flex align-items-center">
            <span class="text-bold text-danger no-wrap">{{ searching }}</span>
            <input v-model="keyword" aria-label="search" class="form-control ml-8" placeholder="Search..." type="text"
              ref="searchInput" @keyup="search($event)" />
            <button class="btn btn-primary btn-flat btn-sm ml-8" @click="addVToken()">Add VToken</button>
            <button v-if="altCoinID" class="btn btn-primary btn-flat btn-sm ml-8 mr-8" @click="reset()">Reset</button>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th class="pointer" @click="sort('ID')">
                  ID
                  <span v-if="sortKey === 'ID'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('MerchantID')">
                  Merchant
                  <span v-if="sortKey === 'MerchantID'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('AltCoinID')">
                  AltCoin
                  <span v-if="sortKey === 'AltCoinID'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('Amount')">
                  Amount
                  <span v-if="sortKey === 'Amount'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('Token')">
                  Token
                  <span v-if="sortKey === 'Token'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('Available')">
                  Available
                  <span v-if="sortKey === 'Available'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('CreatedOn')">
                  Created On
                  <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('ExpiryOn')">
                  ExpiryOn
                  <span v-if="sortKey === 'ExpiryOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('ClaimedOn')">
                  ClaimedOn
                  <span v-if="sortKey === 'ClaimedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th>Comments</th>
                <th class="pointer" @click="sort('Mandated')">
                  Mandated
                  <span v-if="sortKey === 'Mandated'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td class="no-wrap">
                    <span class="pointer mr-8"
                      @click="launchUpdateVTokenModal(record, 'MerchantID', 'Merchant', 'select')">
                      {{ record.ShopTitle + ' (' + record.OwnerNickName + ')' }}
                    </span>
                    <router-link tag="a" :to="{ name: 'user-detail', params: { id: record.MerchantID } }">
                      <i class="fa fa-globe"></i>
                    </router-link>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateVTokenModal(record, 'AltCoinID', 'AltCoin', 'select')">
                      {{ record.CoinName + ' (' + record.LongName + ')' }}
                    </span>
                  </td>
                  <td>
                    <span class="flex align-items-center justify-between">
                      <span class="pointer" @click="launchUpdateVTokenModal(record, 'Amount', 'Amount', 'text')">
                        {{ toFixed(record.Amount) }}
                      </span>
                      <span class="ml-8">
                        <i class="fa fa-clipboard pointer" @click="toConverter(record.Amount, record.AltCoinID)"></i>
                      </span>
                    </span>
                  </td>
                  <td style="min-width: 300px; word-break: break-all;">
                    <span class="flex align-items-center justify-between">
                      <span class="pointer" @click="launchUpdateVTokenModal(record, 'Token', 'Token', 'text')">
                        {{ record.Token ? record.Token.slice(0, 30) + (record.Token.length > 30 ? '...' : '') : '' }}
                      </span>
                      <span class="ml-8">
                        <i class="fa fa-clipboard pointer" @click="copyToClipboard(record.Token)"></i>
                      </span>
                      <button v-if="record.Available" class="btn btn-primary btn-flat btn-sm ml-8"
                        @click="claimVToken(record, index)">Claim
                      </button>
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="toggleVTokenBoolFields(record, 'Available')"
                      v-html="formatBoolean(record.Available)">
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateVTokenModal(record, 'CreatedOn', 'Created On', 'date')">
                      {{ formatDate(record.CreatedOn) }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateVTokenModal(record, 'ExpiryOn', 'Expiry On', 'date')">
                      {{ formatDate(record.ExpiryOn) }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer" @click="launchUpdateVTokenModal(record, 'ClaimedOn', 'Claimed On', 'date')">
                      {{ formatDate(record.ClaimedOn) }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdateVTokenModal(record, 'Comments', 'Comments', 'text')">
                      {{ record.Comments }}
                    </span>
                  </td>
                  <td class="pr-16">
                    <span class="pointer" @click="toggleVTokenBoolFields(record, 'Mandated')"
                      v-html="formatBoolean(record.Mandated)"></span>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="11">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <!-- Update VToken Modal -->
    <update-v-token-modal></update-v-token-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { LIST_MERCHANTS, START_LOADING, STOP_LOADING, SAVE_CONVERTER } from "@/store/keys";
import { formatDate, toFixed, saveNotification, logout } from "@/lib/utils";
import UpdateVTokenModal from "@/components/modals/UpdateVTokenModal";

export default {
  name: "VTokens",
  components: {
    UpdateVTokenModal,
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
      sortKey: "CreatedOn",
      order: "desc",
      altCoinID: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
      "onlineAltCoins",
      "merchants",
      "converter",
    ]),
  },
  methods: {
    showLoading() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoading() {
      this.$swal.close();
    },
    toConverter(amount, altCoinID) {
      const form = { ...this.converter };
      form.source = amount;
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(function () {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, function (err) {
        console.error("Async: Could not copy text: ", err);
      });
    },
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getPaginatedRecords(1);
        }, 500);
      }
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedRecords(1, false);
    },
    getPaginatedRecords(page = 1, isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=vtoken`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword,
          altCoinID: this.altCoinID,
          limit: Number.parseInt(this.meta.limit),
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.tokens;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getVTokenById(id, index) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=vtoken`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id,
        },
      }).then((response) => {
        if (response.status === 200) {
          const record = response.data.data;
          if (index !== -1) {
            this.records.splice(index, 1, record);
          }
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
      });
    },
    claimVToken(record, index) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Call: "VTOKEN",
        MerchantID: record.MerchantID,
        APIKey: record.APIKey,
        AltCoinID: record.AltCoinID,
        Token: record.Token,
        Amount: 1,
        Direction: 0,
        output: "json",
        V2: "",
      };
      this.showLoading();
      this.$http.get(`${apiBaseUrl}/v2REAPI`, {
        params: payload,
      }).then(response => {
        if (response.status === 200) {
          this.stopLoading();
          if (typeof response.data === "string") {
            if (response.data.toLowerCase() === "ok") {
              this.$toast.fire("", "vToken claimed successfully", "success");
              saveNotification("vToken claimed successfully");
              this.getVTokenById(record.ID, index);
            } else {
              this.$toast.fire("", response.data.replace(/^\w/, c => c.toUpperCase()), "error");
              saveNotification(response.data.replace(/^\w/, c => c.toUpperCase()));
            }
          }
        }
      }).catch(error => {
        this.stopLoading();
        console.log(error.response);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    toFixed(val) {
      return toFixed(val);
    },
    reset() {
      this.altCoinID = "";
      this.getPaginatedRecords(1, true);
    },
    addVToken() {
      this.showLoading();
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI?Call=VTOKENInsert`, {
        params: {
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          output: "json",
        },
      }).then(response => {
        if (response.status === 200) {
          this.stopLoading();
          if (response.data.message && response.data.message.toLowerCase() === "no permission") {
            logout();
          } else {
            this.searching = "Please wait ...";
            this.getPaginatedRecords(1, false);
            this.$toast.fire("", "vToken created successfully", "success");
            saveNotification("vToken created successfully");
          }
        }
      }).catch(error => {
        this.stopLoading();
        console.log(error);
      });
    },
    getOptions(key) {
      let options = [];
      switch (key) {
        case "MerchantID":
          options = this.merchants.map(m => {
            return {
              id: m.ID,
              label: m.Name,
            };
          });
          break;
        case "AltCoinID":
          options = this.onlineAltCoins.map(c => {
            return {
              id: c.ID,
              label: c.Name + " - " + c.LongName,
            };
          });
          break;
      }
      return options;
    },
    toggleVTokenBoolFields(record, key) {
      const index = this.records.indexOf(record);
      const payload = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: record.ID,
        Key: key,
        Value: Number(record[key]) === 0 ? 1 : 0,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=VTOKENUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getVTokenById(record.ID, index);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    launchUpdateVTokenModal(record, key, label, type) {
      const index = this.records.indexOf(record);
      const options = this.getOptions(key);
      this.$bus.$emit("update-v-token-modal", {
        title: "Update " + label,
        valueLabel: label,
        record: {
          ID: record.ID,
          Key: key,
          Value: record[key],
        },
        type,
        options,
        confirm: () => {
          this.getVTokenById(record.ID, index);
        },
      });
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.altCoinID = this.$route.query.id;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
    }
    this.sortKey = "CreatedOn";
    this.order = "desc";
    this.getPaginatedRecords();
    this.$store.dispatch(LIST_MERCHANTS, { type: "VToken" });
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
    window.removeEventListener("keydown", this.handleSlashKey);
  },
};
</script>

<style scoped></style>
